<template>
    <div class="notice-wrapper">
        <div
            v-for="(item, index) in dataset"
            :key="index"
            :class="{
                'section-show': hasSectionClass
            }"
            class="notice-container dashboard-container"
        >
            <div class="dashboard-title">
                {{ item.title }}
                <div
                    class="info-icon"
                    tooltip="顯示件數為所有帳號的通知件數"
                    tooltip-touch
                />
            </div>
            <template v-if="noticeData || isLoading">
                <div
                    class="count-container"
                    :class="{
                        loading: isLoading
                    }"
                >
                    <div v-if="isLoading" class="process-loading-icon" />

                    <div
                        class="count-item"
                        v-for="(data, countIndex) in item.notifies"
                        :key="countIndex"
                        :class="{
                            'section-show': hasSectionClass,
                            loading: isLoading
                        }"
                    >
                        <div
                            class="count"
                            :class="{ 'no-notify': data.count === 0 }"
                            @click="
                                data.count
                                    ? rqueryOnNotificationPage(
                                          data.key,
                                          data.payload
                                      )
                                    : ''
                            "
                        >
                            {{ 1000 > data.count ? data.count : '999+' }}
                        </div>
                        <div class="subhead" v-text="data.title" />
                    </div>
                </div>
            </template>
            <DashboardErrorMessage
                v-else-if="!noticeData && !isLoading"
                imgHeight="70px"
                functionName="getDashboardNotificationAPI"
                @reacquire="getNoticeData"
            />
        </div>
    </div>
</template>

<script>
import _ from 'lodash'
import DashboardErrorMessage from '@/containers/home/DashboardErrorMessage.vue'
import { getDashboardNotificationAPI } from '@/assets/javascripts/api.js'

export default {
    name: 'DashboardNotification',
    components: {
        DashboardErrorMessage
    },
    methods: {
        getNoticeData: async function () {
            this.isLoading = true

            try {
                const res = await getDashboardNotificationAPI()
                this.noticeData = res.data
            } catch {
                this.noticeData = null
            } finally {
                this.isLoading = false
            }
        },
        rqueryOnNotificationPage: function (key, payload) {
            this.$setGaEvent(`filterNotify_${key}`, 'cross-page-filter')
            if (key === null) {
                this.$crossPageFilter('TiaInsurance', payload, {}, true)
            } else {
                this.$crossPageFilter('TiaNotification', payload, {}, true)
            }
        }
    },
    computed: {
        dataset: function () {
            const noticeData = _.cloneDeep(this.noticeData)
            const isGetData =
                noticeData == null ? null : Object?.keys(noticeData)?.length > 1
            const emptyList = _.cloneDeep(this.emptyList)

            //避免資料為空時跑版
            if (!isGetData)
                Object.keys(emptyList).forEach((key) => {
                    emptyList[key].notifies = []
                    for (let i = 0; i < emptyList[key].itemCount; i++) {
                        emptyList[key].notifies.push(i)
                    }
                })

            return isGetData ? noticeData : emptyList
        },
        isSectionShow: function () {
            return !this.$store.state.isSectionHidden
        }
    },
    watch: {
        isSectionShow: function (newVal) {
            //解決section CSS動畫的延遲造成跑版
            if (newVal) {
                this.hasSectionClass = this.isSectionShow
            } else {
                setTimeout(() => {
                    this.hasSectionClass = this.isSectionShow
                }, 400)
            }
        }
    },
    data() {
        return {
            isLoading: false,
            hasSectionClass: true,
            emptyList: {
                newContract: { title: '新契約服務', itemCount: 3 },
                policyHolder: { title: '保戶服務', itemCount: 4 },
                claim: { title: '理賠', itemCount: 2 }
            },
            noticeData: null
        }
    },
    async mounted() {
        await this.getNoticeData()
    }
}
</script>
<style lang="scss" scoped>
.notice-wrapper {
    display: flex;
    width: auto;
    flex-wrap: wrap;
    @media screen and (max-width: 634px) {
        flex-direction: column;
    }
}

.notice-container {
    position: relative;
    flex: 1;
    padding: 10px 20px;
    min-width: 160px;
    &.section-show {
        padding-inline: 10px;
    }
    &:not(:last-child) {
        margin-right: 10px;
        margin-bottom: 0;
    }
    @media screen and (max-width: 1280px) {
        padding-inline: 10px;
    }
    @media (min-width: 635px) and (max-width: 1017px) {
        min-height: 161px;
    }
    @media screen and (max-width: 634px) {
        min-height: 93.5px;
        &:not(:last-child) {
            margin-right: 0;
            margin-bottom: 10px;
        }
    }
}

.notice-wrapper {
    position: relative;
    .headline {
        width: fit-content;
        margin: 0 auto;
        color: $sixth-black;
        font-size: 18px;
        margin-bottom: 10px;
        .gradient-dot-icon {
            display: inline-block;
            margin-right: 10px;
            width: 10px;
            height: 10px;
        }
    }

    .count-container {
        flex: 1;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        row-gap: 10px;
        column-gap: auto;
        margin: 0 auto;
        max-width: 200px;
        visibility: visible;
        @media screen and (max-width: 634px) {
            justify-content: flex-start;
            max-width: none;
            column-gap: 10px;
        }
        .count-item {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            width: 88px;

            &.section-show {
                width: 77px;
            }

            @media (min-width: 577px) and (max-width: 1280px) {
                width: 77px;
            }
            @media screen and (max-width: 634px) {
                width: 72px;
            }
            .subhead {
                color: $secondary-grey;
                font-size: 12px;
                word-break: keep-all;
            }
            .count {
                font-size: 24px;
                font-weight: bolder;
                color: $fourth-blue;
                margin-bottom: 5px;
                cursor: pointer;
                &.no-notify {
                    cursor: not-allowed;
                }
            }
        }
        &.loading {
            .count-item {
                visibility: hidden;
            }
        }
        &.not-show {
            visibility: hidden;
        }
    }
}

.process-loading-icon {
    position: absolute;
    top: 45%;
    left: 0;
    right: 0;
    margin: 0 auto;
    width: 30px;
    height: 30px;
}

.info-icon {
    width: 14px;
    height: 14px;
}
</style>
